import axios from 'axios'

export const getCurrentStudent = async (student_id) => {
    console.log(student_id)
    const token = `${localStorage.adminLogin}:${localStorage.adminPassword}`
    const authorizationBasic = window.btoa(token)
    const session_url = process.env.VUE_APP_API_URL + 'student/get_student_by_id'
    const config = {
        method: 'get',
        url: session_url,
        headers: { 'Authorization': 'Basic '+ authorizationBasic },
        params: { student_id: student_id }
    }
    const res = await axios(config)
    return res.data
}

export const getBalanceState = async (balance_id) => {
    const token = `${localStorage.adminLogin}:${localStorage.adminPassword}`
    const authorizationBasic = window.btoa(token)
    const session_url = process.env.VUE_APP_API_URL + 'balance/get_balance_state'
    const config = {
      method: 'get',
      url: session_url,
      headers: { 'Authorization': 'Basic '+ authorizationBasic },
      params: {
        balance_id: balance_id
      }
    }
    const res = await axios(config)
    return res.data.balance_state
  }