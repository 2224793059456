<template>
    <div class="bg-[#ABD3EC] w-[100px] py-2 cursor-pointer rounded-[10px]">
        <div class="w-full overflow-hidden h-[50px] px-2">
            <img :src="apiURL + 'static/' + location.photo">
        </div>
        <div class="bg-[#142552] w-full">
            <div class="text-white text-center font-semibold mt-[10px]">
                {{ location.name }}
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onBeforeMount } from 'vue'

    export default {
        props: {
            location: {
                type: Object,
                required: true
            }
        },
        
        setup(props) {
            const apiURL = ref(process.env.VUE_APP_API_URL)



            return {
                apiURL
            }
        }
    }
</script>
