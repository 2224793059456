<template>
    <div ref="dataInput" @click.left="changeState" :class="'w-[' + blockSize + '] ' + 'h-[' + blockHeight + ']' " @keyup.enter="isDisplay = true" @click.right.prevent="isDisplay = true" class="p-0 m-0 ">
        <div :class="displayStyles" class="w-full cursor-pointer select-none" v-if="isDisplay || onlyDisplay">
            <span v-if="(modelValue?.length > 0 || modelValue > 0) && inputType != 'date'">
                {{ modelValue }}
            </span>
            <span v-else-if="(modelValue?.length > 0 || modelValue > 0) && inputType === 'date'">
                {{ modelValue.split('-')[2].slice(0, 2) + '.' + modelValue.split('-')[1] + '.' + modelValue.split('-')[0] }}
            </span>
            <span class="opacity-40" v-else>
                {{ placeholder }}
            </span>
        </div>
        <input
            v-else-if="!isDisplay && !onlyDisplay"
            :class="inputStyles"
            class="w-full p-0 m-0"
            :type="inputType"
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)" 
        />
    </div>
</template>

<script>
    import {ref} from 'vue'
    export default {
        name: 'data-input',
        props: {
            displayStyles: {
                type: String,
                default: '',
                required: false
            },
            inputStyles: {
                type: String,
                default: '',
                required: false
            },
            modelValue: {
                type: [String, Number, null],
                default: '',
                required: true
            },
            blockSize: {
                type: String,
                default: '100%',
                required: false
            },
            blockHeight: {
                type: String,
                default: '100%',
                required: false
            },
            placeholder: {
                type: String,
                default: '',
                required: false
            },
            onlyDisplay: {
                type: Boolean,
                default: false,
                required: false
            },
            inputType: {
                type: String,
                default: 'text',
                required: false
            }
        },

        setup(props) {
            const isDisplay = ref(true)
            const clickCount= ref(0)

            const changeState = () => {
                clickCount.value++
                
                setTimeout(() => {
                    clickCount.value = 0
                }, 300)
                
                if ((clickCount.value === 2 && isDisplay.value) || (clickCount.value === 1 && (props.modelValue?.length === 0 || +props.modelValue === 0))) {
                    isDisplay.value = false
                    clickCount.value = 0

                }
            }

            return {
                changeState,
                isDisplay
            }
        }
    }
</script>
