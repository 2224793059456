export function createDate(year, month, date) {
    return new Date(year, month, date)
}
  
export function getWeeksInMonth(date) {
    const firstDayOfMonth = createDate(date.getFullYear(), date.getMonth(), 1)
    const lastDayOfMonth = createDate(date.getFullYear(), date.getMonth() + 1, 0)

    let days = []
    for (let i = firstDayOfMonth.getDate(); i <= lastDayOfMonth.getDate(); i++) {
        const day = createDate(date.getFullYear(), date.getMonth(), i)
        days.push({
            id: day.getDate(),
            monthDay: day.getDate(),
            weekDay: day.toLocaleString('en-GB', { weekday: 'long' }),
            date: day
        })
    }

    let weeks = []
    let currentWeek = []
    days.forEach((day, index) => {
        currentWeek.push(day)
        if (index !== 0 && (index + 1) % 7 === 0) {
            weeks.push(currentWeek)
            currentWeek = []
        }
    })
    if (currentWeek.length > 0) {
        weeks.push(currentWeek)
    }
    return weeks
}

export function addingMatchOfTheDayOfTheWeekAndItsNumberInMonth(weeks) {
    return weeks.map(week => {
        return week.map(day => {
        switch (day.weekDay) {
            case 'Monday':
            day.weekDay = 0
            break
            case 'Tuesday':
            day.weekDay = 1
            break
            case 'Wednesday':
            day.weekDay = 2
            break
            case 'Thursday':
            day.weekDay = 3
            break
            case 'Friday':
            day.weekDay = 4
            break
            case 'Saturday':
            day.weekDay = 5
            break
            case 'Sunday':
            day.weekDay = 6
            break
        }
        return day
        })
    })
}

export function formatWeeks(weeks) {
    let days = []
    weeks.forEach(week => week.forEach(day => days.push(day)))

    let formattedWeeks = []
    while (days.length > 0) {
        let week = []
        for (let i = 0; i < 7; i++) {
        const day = days[0]
        try {
            if (day.weekDay == i) {
            week.push(day)
            days.shift()
            continue
            } else {
            week.push(0)
            }
        } catch (e) {
            week.push(0)
        }
        }
        formattedWeeks.push(week)
        week = []
    }
    return formattedWeeks
}
