<template>
    <div class="border-[1px] border-[#9E9E9E] rounded-[6px] overflow-hidden flex justify-between items-center h-[36px]">
        <div class="w-[80%]">
            <input :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" class="w-full bg-[#474849] bg-opacity-20 text-[#fff] bg-transparent pl-[5px] h-[30px] outline-none" type="text" :placeholder="placeholder">
        </div>
        <div @click="openUrl" class="bg-[#6D6D6D] px-3 h-full w-[20%] flex justify-center items-center cursor-pointer">
            <img src="@/assets/icons/link_icon.svg" class="w-4">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'url-component',

        props: {
            modelValue: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            }
        },

        emits: ['update:modelValue'],

        setup(props, { emit }) {
            const openUrl = () => {
                window.open(props.modelValue, '_blank')
            }
            
            return {
                openUrl
            }
        }
    }
</script>
